<template>
  <div class="bodycont">
    <comHeader />
    <div class="aboutbanner">
      <div class="atets">经典案例</div>
    </div>
    <div class="indexMinker indexMinkerSd" style="padding: 25px 0;">
      <div class="cont w1146">
        <div class="dic_inner  diccup">
          <div class="aboutNav" style="display:none;">
            <a href="#" :class="[{ 'on': on == 1 }]" rel="nofollow" @click="change(1)">行政执法监督</a>
            <a href="#" :class="[{ 'on': on == 2 }]" rel="nofollow" @click="change(2)">治安要素</a>
            <a href="#" :class="[{ 'on': on == 3 }]" rel="nofollow" @click="change(3)">交通管控</a>
            <a href="#" :class="[{ 'on': on == 4 }]" rel="nofollow" @click="change(4)">找堵防</a>
            <a href="#" :class="[{ 'on': on == 5 }]" rel="nofollow" @click="change(5)">舆情管控</a>
            <a href="#" :class="[{ 'on': on == 6 }]" rel="nofollow" @click="change(6)">流动人口管控</a>
          </div>
          <div class="dic_choostbox dic_flex">
            <div class="dic_cost">行业分类:</div>
            <div class="dic-items">
              <span :class="['ittems', { 'on': on == 1 }]" @click="change(1)">政法</span>
              <span :class="['ittems', { 'on': on == 2 }]" @click="change(2)">公安</span>
              <span :class="['ittems', { 'on': on == 3 }]" @click="change(3)">电力</span>
              <span :class="['ittems', { 'on': on == 4 }]" @click="change(4)">教育</span>
              <!-- <span :class="['ittems', { 'on': on == 5 }]" @click="change(5)">民生</span>
              <span :class="['ittems', { 'on': on == 6 }]" @click="change(6)">个体</span> -->
            </div>
          </div>
          <div class="aboutctiv">
            <div v-if="on == 1" class="aboutlistt" style="display: flex;flex-wrap: wrap;">
              <div class="aboout-card">
                <div class="about-cadrdetail">郧西行政执法监督云平台</div>
                <div class="about-cards-title">
                  郧西县行政执法监督智慧平台以“一个平台、两大体系、多个系统”为建设思路，包含基础要素、数据采集、执法办案、执法监督、异常预警、统计分析、信息共享几个方面。通过“移动端+电脑端”双端结合，加强行政执法监督的事前、事中、事后三段监管，实现全县行政执法监督的实时、动态、全流程、智能化监督。
                </div>
              </div>

              <div class="aboout-card">
                <div class="about-cadrdetail">邯郸行政执法</div>
                <div class="about-cards-title">
                  邯郸行政执法基于云计算、大数据等技术，建设行政执法监督平台，创新形成“源头管理、节点监控、异常预警、系统考评”的执法与监督工作模式，实现行政执法事项“一把尺子衡量，一个标准处罚，一套流程处置”，实现“执法标准规范、执法程序规范、执法与监督规范”的行政执法与监督新目标，形成促进行政执法自由裁量权运行从弹性向刚性转变，推动行政权力与监督手段从封闭向开放转变。
                </div>
              </div>

            </div>
            <div v-if="on == 2" class="aboutlistt" style="display: flex;flex-wrap: wrap;">
              <div class="aboout-card">
                <div class="about-cadrdetail">钟祥市治安要素管控平台</div>
                <div class="about-cards-title">系统通过管控工作要
                  求及包保关系，进行日常管控任务推送，对未按时
                  完成管控工作的， 系统自动推送督办任务给责任干
                  部进行督导。截止当前， 已在系统完成监护、 日常
                  管控(随访、重点时段管控)等管控工作4534项，对
                  未 及 时 完 成 的 管控任 务 ， 由 主 管 干部/民 警进 行督 导 ， 已 完 成督 导 工作 462项。</div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">找堵防态势感知平台</div>
                <div class="about-cards-title">
                  利用平台便捷高效特点，实现工作实时记录、异常实时上报、动态实时更新、事件实时处理等场景，围绕“市县主战、派出所主防”机制全面为派出所、社区民警减负。
                  通过数据的分析、统计功能，可以让相关领导对辖区内的重点人员管控、重点场所的管理、重大事件的处置做到心中有数，持续强化督导考核，不断释放治理效能。
                </div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">钟祥交警执法小程序</div>
                <div class="about-cards-title">
                  郧西县行政执法监督智慧平台以“一个平台、两大体系、多个系统”为建设思路，包含基础要素、数据采集、执法办案、执法监督、异常预警、统计分析、信息共享几个方面。通过“移动端+电脑端”双端结合，加强行政执法监督的事前、事中、事后三段监管，实现全县行政执法监督的实时、动态、全流程、智能化监督。
                </div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">基础管控中心</div>
                <div class="about-cards-title">
                  基础管控中心采用小屏干事，大屏指挥方式开展工作。以管人、管事、管阵地为切入点，建立一个中心统管基础工作，一个系统统筹基础业务，一个基座汇聚基础数据，一套规范厘清业务标准，一套体系统领基础考核。从基础管控工作的总体层面，解决基础警务工作中的数据底数不清、管辖热点不明、应用支撑不强、预警防控不足等问题，有效发挥群防群治，将公安小闭环升级成党政大闭环，实现统一指挥调度、数据融合贯通、业务相互赋能、制定规范流程、统一任务扎口。
                </div>
              </div>
            </div>
            <div v-if="on == 3" class="aboutlistt">
              <div class="aboout-card">
                <div class="about-cadrdetail">新疆建设兵团</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">中国民航大学</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">汉江集团公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">云南电网公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">海南南山电厂</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">广东电网公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">航天一院</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">贵州电网公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">广东省广州供电局</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">内蒙古电力集团有限责任公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">四川水电集团</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">重庆电网公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">湖南郴电国际</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">宁夏电力公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">广西水利电业集团</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">山东电力公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">北京市电力公司</div>
                <div class="about-cards-title"></div>
              </div>
              <div class="aboout-card">
                <div class="about-cadrdetail">国家电网湖北分公司</div>
                <div class="about-cards-title"></div>
              </div>
            </div>
            <div v-if="on == 4" class="aboutlistt">
              <div class="aboout-card">
                <div class="about-cadrdetail">恩施校园一键报警</div>
                <div class="about-cards-title"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <indexFoot />
    <bNav />
  </div>

</template>

<script>
import comHeader from '@/components/header.vue'
import bNav from '@/components/bottomNav.vue'
import classifyType from '@/components/classify.vue'
import navTitle from '@/components/navtitle.vue'
import indexFoot from '@/components/indexfoot.vue'
import xzzf from '@/components/case/xzzf'
import api from '@/config/api'
import {
  getRequst,
  postRequest
} from '@/api/index'
export default {
  components: {
    comHeader,
    bNav,
    classifyType,
    navTitle,
    indexFoot,
    xzzf
  },
  data() {
    return {
      on: 1
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    change(item) {
      this.on = item
    }
  }
}
</script>
<style lang="less" scoped>
.bodycont {
  background: #f5f7fa;
}

.aboutbanner {
  width: 100%;
  height: 200px;
  background: url(../../assets/images/bj.jpg) no-repeat center center;
  background-size: cover;
}

.aboutNav {
  margin-top: 20px;
  width: 25%;
}

.contsbox {
  padding: 40px 0;
  display: none;
  min-height: 400px;
}

.contsbox.active {
  display: block;
}

.atitle {
  text-align: center;
  font-size: 28px;
}

p {
  line-height: 28px;
  text-indent: 2em;
  margin-top: 20px;
  color: #666;
  text-align: justify;
  font-size: 14px;
}

.flx_align {
  align-items: center;
  justify-content: space-around;
}

.apbox {
  margin-right: 20px;
  width: 700px;
}

.atitle {
  margin-bottom: 40px;
  margin-top: 60px;
}

.on {
  color: #114BA3 !important;
  font-weight: bold;
  // background: #114BA3;
}

.dd {
  width: 500px;
  height: 286px;
  overflow: hidden;
}

.dd img {
  width: 100%;
  height: auto;
}

.dt {
  margin-top: 30px;
  font-size: 18px;
}

.ds {
  font-size: 16px;
  margin-top: 15px;
}

.atets {
  width: 1280px;
  margin: 0 auto;
  font-family: "WHDICFONT" !important;
  color: #ffffff;
  font-size: 36px;
  padding-top: 120px;
}

.aboutNav {
  background: #ffffff;
  min-height: 800px;
}

.aboutNav a {
  display: block;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  margin-bottom: 30px;

}

.aboutctiv {
  width: 100%;
  // margin-left: 30px;
  margin-top: 30px;
  min-height: 600px;
  background: #ffffff;
}

.diccup {
  justify-content: space-between;
}

.ittems {
  display: inline-block;
  padding: 0 20px;
  font-size: 18px;
  cursor: pointer;
}

.dic_cost {
  font-size: 18px;

}

.dic_choostbox {
  margin-top: 20px;
}

.aboout-card {
  width: 28%;
  float: left;
  background: #F7F8FA;
  padding: 30px;
  margin-top: 30px;
  margin-left: 50px;
  border-radius: 3px;
}

.about-cadrdetail {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 15px;
}

.about-cards-title {
  color: #4E5969;
  line-height: 28px;
}

.aboutlistt {
  overflow: hidden;
  padding: 30px 0 60px;
  text-align: justify;
}
</style>
