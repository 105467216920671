<template>
  <div class="xa_cpb">
    <!-- <div class="dic_title">行政执法监督云平台</div> -->
    <!-- <div class="dic_sub_title">深耕三大领域,打造一站式数字化服务</div> -->
    <div class="zcboxs">
      <h3 class="htitle">业务介绍</h3>
      <div class="ptext zfirst">
        以司法部“数字法治
        智慧司法”的要求为纲，建设行政执法监督的实时、动态、全流程、智能化监督
      </div>
      <div class="imag">
        <img src="../../assets/images/jjfa/ttt.png" alt="" style="width:85%;display:block;margin:0 auto;" />
      </div>
      <h3 class="htitle">功能应用</h3>
      <div class="cars-fa">
        <el-row :gutter="40">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe62b;</i>基础数据库管理子系统</div>
              <div class="mdess">
                包含部门、人员、职权、案由、裁量、行政相对人、法律法规、规范性文件、审管联动信息等.
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe635;</i>数据支撑子系统</div>
              <div class="mdess">
                包含数据资源管理、数据标准管理、数据汇聚、数据清洗、数据抽取、数据匹配、数据共享、汇聚和共享实施等.
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe636;</i>多部门联合执法子系统</div>
              <div class="mdess">
                包含文书管理、流程管理、案件办理、异常管理、报表生成、数据统计等模块.
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="40">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe64c;</i>综合监管应用子系统</div>
              <div class="mdess">
                包含综合监管场景管理、一库两单、任务管理、线索管理、异常管理、综合分析、移动监管等模块.
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe64b;</i>效能考核子系统</div>
              <div class="mdess">
                包含考核模型、考核实施、考核数据分析、考核报表导入导出、考核信息一键生成等模块.
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="mtitles"><i class="iconfont icort">&#xe650;</i>大数据辅助决策子系统</div>
              <div class="mdess">
                包含信息展示、执法监督统计分析、执法监督专项分析、执法领域数据分析、执法监督管理驾驶舱等.
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <h3 class="htitle">典型案例</h3>
      <div class="anpics">
          <el-row :gutter="40">
          <el-col :span="12"><div class="grid-content bg-purples">
            <div class="mtitless">郧西县行政执法监督云平台建设</div>
            <div class="mdess mdesss">郧西县行政执法监督智慧平台以“一个平台、两大体系、多个系统”为建设思路，包含基础要素、数据采集、执法办案、执法监督、异常预警、统计分析、信息共享几个方面。通过“移动端+电脑端”双端结合，加强行政执法监督的事前、事中、事后三段监管，实现全县行政执法监督的实时、动态、全流程、智能化监督。</div>
            </div></el-col>
          <el-col :span="12"><div class="grid-content bg-purpless">
              <div class="anboxes">
                <img src="../../assets/images/jjfa/xzzfs.png" alt="" />
                </div>
            </div></el-col>
      </el-row>
      
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
export default {
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {},
  mounted() {
    //this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      };
      postRequest(api.aboutUsId, data).then((res) => {
        this.dataInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xa_cpb {
  width: 100%;
  background: #ffffff;
  padding: 30px 0 80px;
}
.cpcard {
  width: 25%;
  margin-right: 25px;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.1), inset 0 1px 0 0 #fff;
  padding-bottom: 0;
}
.cpcard img {
  width: 100%;
  height: 100%;
}
.cpboxs {
  margin-top: 0;
}
.cpboxs .cpcard:last-of-type {
  margin-right: 0;
}
.cpcard_title {
  padding: 0;
  position: relative;
}
.cpcard_title i {
  position: absolute;
  left: 0;
  top: 43px;
}
.cpcard_title .cpcard_t0 {
  font-size: 18px;
  color: #17181b;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgba(0, 11, 23, 0.06);
  padding-bottom: 19px;
  padding-left: 40px;
}
.cp_contitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 15px;
}
.cp_ctext {
  font-size: 14px;
  color: #808082;
  line-height: 24px;
  background: #ffffff;
  height: 230px;
}
.cpcard-des {
  border: 1px solid rgba(0, 113, 239, 0.7);
  color: #0071ef;
  font-size: 12px;
  display: inline-block;
  padding-left: 3.5px;
  padding-right: 3.5px;
  border-radius: 3px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}
.btns1 {
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #fff;
  background-color: #0071ef;
  margin-right: 24px;
  margin-top: 30px;
}
.cp_btns {
  opacity: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  // -webkit-transition: all .3s ease-in-out;
  // transition: all .3s ease-in-out;
  height: 62px;
  display: flex;
  justify-content: center;
}
.bt02 {
  background: none;
  border: 1px #ffffff solid;
  color: #ffffff;
}
.cpcard {
  overflow: hidden;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  height: 280px;
  overflow: hidden;
  box-shadow: 0 12px 48px 0 rgba(0, 33, 71, 0.05), inset 0 1px 0 0 #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.cpcard:hover {
  height: 296px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateY(-4px);
}
.cpboxs2 .cpcard:hover {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_btns {
  opacity: 1;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard_title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cpcard_title {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpcard:hover .cp_ctext {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cpboxs {
  height: 306px;
}
.itemnamse {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.htitle {
  margin-bottom: 30px;
  font-size: 24px;
  text-indent: 2em;
  margin-top: 60px;
  position: relative;
  text-align: center;
}
.htitle::before{
  content:'';
  position: absolute;
  width:40px;
  height:4px;
  background:#004bb2;
  left:50%;
  margin-left:5px;
  bottom:-14px;
}
.ptext {
  text-indent: 2em;
  line-height: 28px;
  text-align: justify;
  margin-bottom: 20px;
}
.zcboxs {
  padding: 0 30px;
}
.imag {
  width: 80%;
  overflow: hidden;
  margin: 20px auto;
}
.imag img {
  width: 100%;
  height: auto;
}
.zfirst {
  text-align: center;
  margin-top: 50px;
}
.bg-purple {
  background: linear-gradient(360deg, rgba(147, 185, 253, 0) 0%, rgba(0, 79, 255, 0.03) 100%);
  padding: 30px;
  height: 230px;
  margin: 20px 0;
  border:1px #004bb2 solid;
  border-radius: 3px;
}
.mtitles {
  text-align: center;
  line-height: 60px;
  font-size: 18px;
}
.mdess {
  padding: 5px 0;
  line-height: 28px;
  font-size: 15px;
}
.icort{
  display: inline-block;
  margin-right:15px;
  font-size: 48px;
  color:#004bb2;
}
.cars-fa{
  width:90%;
  margin:0 auto;
}
.anboxes{
  width:100%;
  height:300px;
  overflow: hidden;
}
.anboxes image{
  width:100%;
  height:auto;
}
.anpics{
  margin-top:60px;
}
.mtitless{
  text-align: left;
  font-size: 22px;
  margin-bottom:20px;
}
.bg-purples{
  padding-left:80px;
}
.mdesss{
  font-size: 16px;
  text-align: justify;
}
</style>
