<template>
  <div class="xa_cpb">
        <div class="dic_title dic_c_white">三大产品线功能免费演示，带你体验数字化助力企业带来的巨变</div>
       <div class="dic_inner ">
         <div class="zs_box dic_flex dic_jsa">
           <div class="zs_items"  @click="getDialog">
             <div class="zs_pics">
               <svg t="1709627912683" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1192" width="60" height="60"><path d="M442.06 317.12h321.59v367.01H442.06z" fill="#A9C6FF" p-id="1193"></path><path d="M442.06 422.12h380.68v322H442.06z" fill="#A9C6FF" p-id="1194"></path><path d="M892.06 744.86h-238V416.12h238z m-158-80h78V496.12h-78zM368.06 744.86h-238V416.12h238z m-158-80h78V496.12h-78z" fill="#333333" p-id="1195"></path><path d="M813.06 456.12h-80v-221H288.72v221h-80v-301h604.34v301zM813.06 895.12h-295v-80h215v-110h80v190z" fill="#333333" p-id="1196"></path></svg>
            </div>
             <div class="zs_test">免费体验</div>
           </div>
             <div class="zs_items"  @click="getDialog">
              <div class="zs_pics">
                <svg t="1709628010627" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1347" width="60" height="60"><path d="M851.78 437H300v289.75h39.65l0.71 109.13 212.21-109.13h299.21V437z" fill="#A9C6FF" p-id="1348"></path><path d="M301.14 355.25h395.28v80H301.14zM301.14 521.25h395.28v80H301.14z" fill="#333333" p-id="1349"></path><path d="M287.36 901.21l-0.87-134.46H119.21v-583h759.14v583H548.83z m-88.15-214.46H366l0.54 83.8 163-83.8h268.81v-423H199.21z" fill="#333333" p-id="1350"></path></svg>
              </div>
              <div class="zs_test">预约演示</div>
           </div>
            <div class="zs_items"  @click="getDialog">
              <div class="zs_pics">
                <svg t="1709628056622" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1497" width="60" height="60"><path d="M766.35 519.53a92.51 92.51 0 0 1 70.82-89.93v-33.9H376v412.66h461.17V609.47a92.53 92.53 0 0 1-70.82-89.94z" fill="#A9C6FF" p-id="1498"></path><path d="M877.56 848H145.91V600.57l29.4-8.08a77.27 77.27 0 0 0 0-148.95l-29.4-8.08V188h731.65v247.56l-30.65 7.37a77.25 77.25 0 0 0 0 150.18l30.65 7.36z m-651.65-80h571.65V660a157.2 157.2 0 0 1 0-284V268H225.91v109.79a157.26 157.26 0 0 1 0 280.46z" fill="#333333" p-id="1499"></path><path d="M377.93 440.97h266.8v80h-266.8zM377.93 558.05h266.8v80h-266.8z" fill="#333333" p-id="1500"></path><path d="M471.33 507.42h80V693.4h-80zM511.33 480.72l-127.83-83.2 43.64-67.04 84.19 54.79 84.19-54.79 43.64 67.04-127.83 83.2z" fill="#333333" p-id="1501"></path></svg>
              </div>
              <div class="zs_test">价格咨询</div>
           </div>
            <div class="zs_items"  @click="getDialog">
             <div class="zs_pics">
               <svg t="1709628092883" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1650" width="60" height="60"><path d="M308.17 375h554.89v394.2H308.17z" fill="#A9C6FF" p-id="1651"></path><path d="M904 812.87H122V213.61h782z m-702-80h622V293.61H202z" fill="#333333" p-id="1652"></path><path d="M512.72 552.04L164.58 448.48l22.81-76.68 325.78 96.91 339.71-97.03 21.97 76.92-362.13 103.44z" fill="#333333" p-id="1653"></path></svg>
             </div>
             <div class="zs_test">联系我们</div>
           </div>
         </div>
       </div>
        <Dialog ref="dialog" />
  </div>
</template>

<script>
  import api from '@/config/api'
  import Dialog from '@/components/Dialog.vue'
  import {
    getRequst,postRequest
  } from '@/api/index'
export default {
   components: {
     Dialog
   },
  data() {
    return {
       dataInfo:{},
     }
  },
  created() {

  },
  mounted() {
    //this.getInfo()
  },
  methods: {
     getDialog(){
       this.$refs.dialog.openDialog()
    },
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      }
      postRequest(api.aboutUsId, data).then(res => {
         this.dataInfo = res.data
      })

    },
  },

}
</script>

<style lang="less" scoped>
  .xa_cpb{
    width:100%;
    background: url('../assets/images/index/bgfooter.png') no-repeat center center;
    padding:20px 0 20px;
    height:395px;
  }
  .zs_box{
      margin-top:60px;
    }
    .zs_pics{
      width:100px;
      height:100px;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .zs_test{
      font-size: 18px;
      text-align: center;
      line-height: 18px;
      margin:0 auto;
      margin-top:30px;
      width:90px;
      color:rgba(255,255,255,.9);
    }
    .dic_c_white{
      font-weight: 500;
      font-size:30px;
    }
    .zs_items{
      cursor:pointer;
    }
</style>
